import {
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  IconProps,
  Stack,
  Text,
} from "@chakra-ui/react";

export const Header = () => {
  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          Wir{" "}
          <Text as={"span"} color="#98a967">
            heiraten
          </Text>
        </Heading>
        <Text color={"gray.500"} maxW={"3xl"}>
          Wir laden euch herzlich ein, dabei zu sein, wenn wir vor Euch und Gott
          “Ja” zueinander sagen. Gemeinsam wollen wir diesen besonderen Tag mit
          Euch verbringen und unsere Liebe feiern.
        </Text>
        <Stack spacing={6} direction={"row"}>
          <Button
            rounded={"full"}
            px={6}
            colorScheme={"orange"}
            bg={"#98a967"}
            _hover={{ bg: "#7b8953" }}
            onClick={() =>
              document
                .getElementById("zusagen")!
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Jetzt zusagen
          </Button>
          <Button
            rounded={"full"}
            px={6}
            onClick={() =>
              document
                .getElementById("mehrInfos")!
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Mehr Infos
          </Button>
        </Stack>
        <Flex w={"full"}>
          <Illustration
            height={{ sm: "24rem", lg: "28rem" }}
            mt={{ base: 12, sm: 16 }}
          />
        </Flex>
      </Stack>
    </Container>
  );
};

export const Illustration = (props: IconProps) => {
  return (
    <Icon
      width="100%"
      viewBox="0 0 684.76331 506.49355"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <polygon
        points="140.266 494.709 150.838 494.708 155.867 453.933 140.264 453.934 140.266 494.709"
        fill="#ffb6b6"
      />
      <path
        d="M395.18793,688.01066l2.97556-.00012,11.617-4.72438,6.22639,4.72366h.00084a13.2682,13.2682,0,0,1,13.26749,13.26728v.43114l-34.08669.00126Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <polygon
        points="189.379 494.709 199.951 494.708 204.98 453.933 189.377 453.934 189.379 494.709"
        fill="#ffb6b6"
      />
      <path
        d="M444.30085,688.01066l2.97556-.00012,11.617-4.72438,6.22639,4.72366h.00084a13.2682,13.2682,0,0,1,13.26749,13.26728v.43114l-34.08669.00126Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <path
        d="M455.44764,468.31824,372.389,470.485l1.4445,40.44594s-1.4445,10.83373,1.4445,13.72273,4.33349,2.889,2.889,7.94474,6.18746,13.31326,5.26048,14.24024-3.09373,5.98272-3.09373,5.98272l7.22249,41.16819s5.05574,71.50263,6.50024,72.94713,2.889,0,1.44449,3.61124-2.889,2.16675-1.44449,3.61125a53.19561,53.19561,0,0,1,3.61124,4.33349h17.46193s1.31654-7.22249,1.31654-7.94474,1.4445-5.05574,1.4445-5.778-1.27673-1.999-1.27673-1.999a23.76122,23.76122,0,0,1-.89-4.50126c0-1.44449-4.33349-57.05765-4.33349-57.05765l8.667-72.22489,20.94521,70.78039s0,62.1134,1.4445,63.5579,1.4445.72225.72225,3.61124-3.61125,2.16675-1.4445,4.33349,2.889-1.44449,2.16675,2.16675l-.72225,3.61124,21.66746.31s2.889-6.08794,1.4445-8.25468-1.35783-1.5753.40446-5.12115,2.48454-4.26809,1.76229-4.99034-.72225-4.56381-.72225-4.56381l-3.61125-69.10557s-.72224-72.94713-.72224-75.11388a7.20412,7.20412,0,0,1,.6-3.24922v-2.94108L461.22563,493.597Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <path
        d="M549.4444,310.79389a8.0652,8.0652,0,0,1-11.60959,4.2615l-36.05395,30.20512-1.9594-14.76429,35.26391-26.99494a8.1089,8.1089,0,0,1,14.359,7.29261Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#ffb6b6"
      />
      <circle cx="132.8478" cy="104.11708" r="22.38971" fill="#ffb6b6" />
      <path
        d="M408.61743,278.58776c-2.08444-4.9682-11.01748-7.918-25.15293-7.47373s-16.61088,17.301-16.61088,17.301c-7.7858,7.25682,4.78422,29.91414,6.965,26.79029,2.18079-3.1238,5.84226-.17107,5.84226-.17107l-3.48932-19.62672c3.68483,1.41759,7.6478,2.36941,11.11371.71789l-5.74252-3.07069q7.13905.88837,14.27815,1.77675l-5.88836-4.53c.14274-.04713.28548-.09609.4283-.14415a20.92492,20.92492,0,0,0,7.45992,1.6937c3.002-.02239,6.19233-1.42914,7.4503-4.155a6.13852,6.13852,0,0,0,.53276-2.3761c4.13922,1.34387,6.31262,6.19288,6.31262,6.19288A76.403,76.403,0,0,0,408.61743,278.58776Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#b68d34"
      />
      <path
        d="M408.38817,324.26143c-5,0-8.22.69-16,2-14.36,2.42-5.76,16.06452-5.76,16.06452s.65-.33-1.11,0-7.76,5.52-7.76,5.52l-10.73,9.53c-16.17,12.69-12.55,32.49-12.55,32.49l23.58,23.73-10.33,47.61s4.38995,7.07,2.11993,10.24,20,2.26,20,2.26c15.37,6.85,69.52,1.06,69.52,1.06-.75-1.96-8-17.15-8-17.15,3.15-5.44.15-13.3.15-13.3s-3.13-37.18006-5.13-42.32,5.12-32.99,9.8-32.69,34.91-15.12,34.91-15.12-.76-1.21,9.22-5.29a48.91145,48.91145,0,0,0,15.49-9.77l4.1-8.19c-1.97.76-8.62-11.78-8.62-11.78l-2.9,1.1055-1.68006-.4855s-13.11,3.73-15.79,6.45-3.58,1.96-6,2.57-2.72,2.31-3.48,2.12-2.11,2.11-3.17,1.81-3.33.91-4.84,1.21-12.24,4.84-12.24,4.84c-6.64-3.36-24.64-2-24.64-2s-5.89-3.47-7.56,0a5.68995,5.68995,0,0,1-5.13,3.33l-7.18-3.07C416.26085,338.5591,408.42918,322.68263,408.38817,324.26143Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#98a967"
      />
      <path
        d="M374.10876,510.59463a8.06519,8.06519,0,0,1,1.80006-12.2353L365.7364,458.37089l13.90766,5.32924,7.72924,37.76127a8.1089,8.1089,0,0,1-13.26454,9.13323Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#ffb6b6"
      />
      <path
        d="M355.38817,382.26143l-1.25622,15.9685s-1.56154,17.54331-1.15266,20.7874,1.17031,23.05512,1.17031,23.05512l13.23857,26.189,15-4-.89764-12.28549.89764,11.28549,2-10-5-17,3-6-1.51287-20.14116Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#98a967"
      />
      <path
        d="M710.52922,344.94078l4.95224,1.06121a28.50966,28.50966,0,0,1,.76987-6.1487,18.46361,18.46361,0,0,0,2.78828,6.90816l28.40245,6.06545,27.2684-28.777c.04166-14.38846-7.94848-25.32289-22.27457-25.32289-.86354,0-1.75825.04159-2.66338.12484A30.299,30.299,0,0,0,715.0861,322.6662C704.932,332.66427,708.615,338.5424,710.52922,344.94078Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#b68d34"
      />
      <polygon
        points="438.947 484.723 428.645 484.723 423.744 444.985 438.949 444.985 438.947 484.723"
        fill="#ffb6b6"
      />
      <path
        d="M701.17424,701.03875h-7.31052l-1.3049-6.902-3.342,6.902H669.82754a4.35853,4.35853,0,0,1-2.47666-7.94519L682.83456,682.4v-6.97769l16.28613.97206Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <polygon
        points="559.646 473.509 550.685 478.593 526.812 446.449 540.037 438.946 559.646 473.509"
        fill="#ffb6b6"
      />
      <path
        d="M830.92673,685.0023,824.56841,688.61l-4.541-5.35905.49936,7.65228L803.663,700.4717a4.35852,4.35852,0,0,1-6.075-5.68811l8.1897-16.94184-3.44345-6.06884,14.64457-7.19165Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <path
        d="M658.484,351.90278a9.12421,9.12421,0,0,1,7.35419,11.90215l15.7652,13.64545-5.95565,11.60294-21.99286-19.58271a9.17367,9.17367,0,0,1,4.82912-17.56783Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#ffb6b6"
      />
      <path
        d="M672.11159,394.37186l-10.5443-16.775,11.72412-11.98658,8.32651,10.132,10.31465,4.60188,14.15584,8.625c2.81463-3.313,24.505-28.23054,34.18306-21.056a9.74325,9.74325,0,0,1,4.196,6.983c.6838,6.67049-5.2947,13.45568-5.52165,13.70691l-12.785,17.24649c-.39713,1.08331-4.62782,12.25145-11.22887,15.25572a7.50669,7.50669,0,0,1-6.035.19721c-17.49221-6.81929-29.27829-14.51138-34.53712-22.47609A20.21593,20.21593,0,0,1,672.11159,394.37186Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <path
        d="M711.12809,429.38423s-30.29263,48.25214-24.80452,53.74024,14.02832,6.92923,14.02832,6.92923l18.90033-24.49117Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <path
        d="M737.91178,363.48373l-14.27709,9.658a36.82745,36.82745,0,0,0-14.348,42.01287l4.0194,12.217Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <polygon
        points="505.539 298.445 507.448 305.093 513.961 385.264 546.501 445.908 530.97 457.001 486.597 399.316 469.047 342.038 441.484 468.834 420.777 467.355 435.158 291.554 462.449 242.071 505.539 298.445"
        fill="#2f2e41"
      />
      <path
        d="M740.33984,365.91232,720.469,382.15178a27.06166,27.06166,0,0,0-8.98333,13.83374v0a27.06206,27.06206,0,0,0-.94974,7.57473l.59212,35.26352L692.9093,487.515s49.393,18.65957,55.9787,2.19524S740.33984,365.91232,740.33984,365.91232Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#98a967"
      />
      <path
        d="M712.66649,473.24588l-3.29287-13.17146.80418-32.7728c.06391-1.90428,10.2146-31.54226,23.00673-63.0237l11.0052-11.00473,5.93282-3.86082,13.05962,12.05474s-1.66054,32.99672-1.12272,66.77629c.14881,9.34584-1.49405,20.26062-2.19524,28.53816l6.0004,26.66224v14.84085l-.39031.07887c-.3896.07887-6.40306-.10681-31.07769-.10681C713.952,498.25671,727.56268,483.76123,712.66649,473.24588Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <polygon
        points="500.028 194.141 503.029 212.864 482.721 221.241 500.028 194.141"
        opacity="0.2"
      />
      <circle cx="479.57187" cy="129.26486" r="19.88195" fill="#ffb6b6" />
      <path
        d="M770.50425,426.89681l0,0a36.52061,36.52061,0,0,1-43.8385,8.86091l-4.32724-2.14156Z"
        transform="translate(-257.61835 -196.75322)"
        opacity="0.2"
      />
      <path
        d="M701.31983,422.32007a9.12421,9.12421,0,0,1,13.98452.42232l20.07437-5.63557,6.40006,11.36386L713.31811,436.031a9.17367,9.17367,0,0,1-11.99828-13.711Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#ffb6b6"
      />
      <path
        d="M744.26813,434.3524l-19.8087-.44567L720.91812,417.518l13.04323-1.36455,9.52425-6.07108,14.99811-7.05933c-1.21345-4.1744-10.05282-36.00553,1.26739-40.12767a9.74322,9.74322,0,0,1,8.13927.34684c5.943,3.10538,8.31033,11.83333,8.3949,12.16116L783.6311,395.576c.68512.92839,7.67294,10.61323,6.54216,17.77709a7.50673,7.50673,0,0,1-3.16121,5.1446c-15.33,10.83843-28.24374,16.43439-37.788,16.43344A20.21627,20.21627,0,0,1,744.26813,434.3524Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#2f2e41"
      />
      <path
        d="M713.34868,312.93858c1.59182,2.07035,7.0746,3.90144,13.5874,5.37877-.01041-.1352-.01041-.27045-.01041-.40571a28.51028,28.51028,0,0,1,.76987-6.1487,18.46349,18.46349,0,0,0,2.78829,6.90816c.104.15609.208.31211.32251.46819,5.58685,1.1028,11.49622,1.95592,16.12594,2.52812-3.18357,11.465-15.38731,20.87009-14.066,27.84067l35.758,7.30344a28.03231,28.03231,0,0,1,.68663-8.63516A18.58683,18.58683,0,0,0,774.492,358.008l3.0379.6242.90513-4.46322c7.84447-18.84132-3.08991-38.13-21.00534-48.1073a21.777,21.777,0,0,0-13.79549-9.25944l-4.46322-.91554A21.88028,21.88028,0,0,0,713.34868,312.93858Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#b68d34"
      />
      <path
        d="M274.70863,676.84207l-.48174-10.83182a34.98315,34.98315,0,0,0-16.31744-4.13057c7.837,6.4073,6.8576,18.75841,12.17087,27.37474a21.04264,21.04264,0,0,0,15.43077,9.72408l6.55827,4.01542a35.26075,35.26075,0,0,0-7.43206-28.57312,34.06019,34.06019,0,0,0-6.25958-5.81618C276.807,672.7463,274.70863,676.84207,274.70863,676.84207Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#f2f2f2"
      />
      <path
        d="M258.809,703.24678H941.191a1.19069,1.19069,0,0,0,0-2.38137H258.809a1.19069,1.19069,0,0,0,0,2.38137Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#cacaca"
      />
      <path
        d="M628.99905,264.80718c2.47411,10.8697,3.65,23.19285-2.94906,32.92a22.60018,22.60018,0,0,1-11.69787,8.86176,33.19417,33.19417,0,0,1-14.9065.9156c-11.25543-1.55938-21.25864-7.42214-32.32809-9.69833-4.88493-1.00449-10.06918-1.33649-14.77168.59669-3.52347,1.44848-7.23465,4.36064-7.25156,8.51474-.01737,4.2658,3.6876,7.09707,7.37228,8.40863,4.91663,1.75007,10.02624,1.07273,15.12595.85214,5.49244-.23758,11.093.25908,15.65586,3.62276a27.22947,27.22947,0,0,1,8.70142,11.79727c2.29343,5.5661,3.22429,11.55034,4.69076,17.35773a77.07252,77.07252,0,0,0,5.53231,15.12563,78.3522,78.3522,0,0,0,19.14376,24.81466c3.93926,3.39016,8.72569,6.84394,14.10564,7.15467,4.14959.23966,9.19371-1.50406,10.79318-5.707a8.19475,8.19475,0,0,0-3.83658-9.72918c-3.55622-1.79454-7.42916.76542-8.57758,4.279a5.47726,5.47726,0,0,0,.57067,4.93908c1.07829,1.60788-1.51933,3.11129-2.59041,1.51415-2.48685-3.70823-1.187-8.69547,1.85866-11.66586a8.737,8.737,0,0,1,11.499-.857,11.36119,11.36119,0,0,1,4.1531,11.73953c-1.27929,4.6166-5.81562,7.5531-10.34564,8.2806-5.5941.89838-10.97782-1.30019-15.49346-4.47649a69.4528,69.4528,0,0,1-12.26916-11.333,81.43966,81.43966,0,0,1-16.24638-29.049c-3.30206-10.58121-3.54225-24.55935-13.354-31.70534-5.04883-3.67711-11.2352-3.26928-17.15486-2.93176-5.22555.298-10.32313.24379-15.117-2.12187-3.95837-1.95338-7.15208-5.55889-7.21812-10.15242-.06666-4.6372,3.22567-8.36592,7.10487-10.4786,10.24787-5.58119,22.55427-1.08,32.622,2.64874,10.12432,3.74972,21.68375,8.15073,32.4433,4.20478a20.12461,20.12461,0,0,0,11.11766-10.62283c2.60543-5.52533,2.97613-11.79462,2.37425-17.791a77.42736,77.42736,0,0,0-1.64958-9.43093c-.42785-1.87971,2.46384-2.68225,2.89284-.79751Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#3f3d56"
      />
      <path
        d="M610.61062,234.31506a100.84349,100.84349,0,0,0,13.26,27.84q2.16,3.15,4.54,6.14a32.49279,32.49279,0,0,1,4.45-6.22c6.05-6.65,14.16-11,22.03-15.36,7.87-4.36,15.86-9.04,21.42-16.11a28.39923,28.39923,0,0,0,5.24-10.79c1.26-5.33.78-10.89-2.33-15.34-4.52-6.47-13.53-8.75-21.19-6.88-7.67,1.87-14.09,7.2-19.06994,13.32,1.62994-7.52-6.3-14.78-13.97-14.12-7.67.67-13.82,7.37-15.86,14.79C607.08065,218.99505,608.41061,226.93506,610.61062,234.31506Z"
        transform="translate(-257.61835 -196.75322)"
        fill="#98a967"
      />
      <g opacity="0.2">
        <path
          d="M623.87063,262.155q2.16,3.15,4.54,6.14a32.49279,32.49279,0,0,1,4.45-6.22c6.05-6.65,14.16-11,22.03-15.36,7.87-4.36,15.86-9.04,21.42-16.11a28.39923,28.39923,0,0,0,5.24-10.79,75.76561,75.76561,0,0,0-45.9,24.35A73.70884,73.70884,0,0,0,623.87063,262.155Z"
          transform="translate(-257.61835 -196.75322)"
        />
      </g>
    </Icon>
  );
};
