import { Center, Text, VStack } from "@chakra-ui/react";
import { Header } from "./Header";
import { Itinerary } from "./Itinerary";
import { Locations } from "./Locations";
import { Parken } from "./Parken";
import { Trauspruch } from "./Trauspruch";
import { Zusagen } from "./Zusagen";

export const App = () => (
  <VStack alignItems="stretch" spacing="-1px" color="#302e41">
    <Header />
    <Itinerary />
    <Locations />
    <Trauspruch />
    <Parken />
    <Zusagen />
    <Center bg="#98a967">
      <Text mt={8} mb={2} fontSize="sm" color="gray.100">
        Icons made by{" "}
        <a href="https://www.freepik.com" title="Freepik">
          Freepik
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </Text>
    </Center>
  </VStack>
);
