import { CheckCircleIcon, CheckIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormEventHandler, useState } from "react";

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const Zusagen = () => {
  const [formState, setFormState] = useState<{
    name: string;
    numberOfPersons?: number;
    confirmationState?: boolean;
  }>({
    name: "",
    numberOfPersons: undefined,
    confirmationState: undefined,
  });
  const [submissionState, setSubmissionState] = useState<
    "idle" | "submitting" | "submitted"
  >("idle");

  const [hasVerificationError, setHasVerificationErrors] = useState(false);

  const hasName = !!formState.name;
  const hasConfirmation = formState.confirmationState !== undefined;
  const hasNumberOfPersons =
    !formState.confirmationState || !!formState.numberOfPersons;

  const sendToast = useToast();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    setSubmissionState("submitting");
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "confirmation",
        name: formState.name,
        numberOfPersons: formState.numberOfPersons,
        zusage: formState.confirmationState,
        absage: !formState.confirmationState,
      }),
    })
      .then(() => {
        setSubmissionState("submitted");
        if (formState.confirmationState === true) {
          sendToast({
            colorScheme: "gray",
            position: "bottom",
            title: "Zusage erhalten :)",
            description: "Sehr schön, dass du kommst. Wir freuen uns auf dich!",
            icon: <CheckCircleIcon />,
          });
        } else {
          sendToast({
            colorScheme: "gray",
            position: "bottom",
            title: "Absage erhalten :(",
            description:
              "Wir haben deine Absage erhalten. Schade, dass es nicht klappt",
            icon: <CheckCircleIcon />,
          });
        }
      })
      .catch(() => {
        sendToast({
          colorScheme: "orange",
          position: "bottom",
          title: "Oh-oh!",
          description:
            "Da hat leider etwas nicht geklappt. Dein Formular konnte nicht abgeschickt werden :(",
          icon: <WarningIcon />,
        });
        setSubmissionState("idle");
      });

    e.preventDefault();
  };

  return (
    <VStack spacing="-1px" alignItems="stretch" pt={20}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#98a967"
          fill-opacity="1"
          d="M0,0L60,32C120,64,240,128,360,138.7C480,149,600,107,720,112C840,117,960,171,1080,186.7C1200,203,1320,181,1380,170.7L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
      <Flex bg="#98a967" justifyContent="center" pb={36} mt={-6}>
        <Stack
          boxShadow={"2xl"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          p={10}
          spacing={8}
          align={"center"}
          width={{ base: "90%", lg: "30%" }}
          id="zusagen"
          alignItems="stretch"
        >
          <Image src="subscribe.svg" w="200px" mx="auto" />
          <Stack align={"center"} spacing={2}>
            <Heading
              fontSize={"3xl"}
              color={useColorModeValue("gray.800", "gray.200")}
            >
              Zusagen
            </Heading>
            <Text fontSize={"lg"} color={"gray.500"}>
              Sag uns bitte bis zum 31. Mai Bescheid
            </Text>
          </Stack>
          <form name="confirmation" onSubmit={handleSubmit}>
            <Stack spacing={4} direction="column" w={"full"}>
              <FormControl isInvalid={hasVerificationError && !hasName}>
                <FormLabel mb={0}>Wer bist du?</FormLabel>
                <Input
                  type="text"
                  color={useColorModeValue("gray.800", "gray.200")}
                  bg={useColorModeValue("gray.100", "gray.600")}
                  rounded={"full"}
                  border={0}
                  _focus={{
                    bg: useColorModeValue("gray.200", "gray.800"),
                    outline: "none",
                  }}
                  _active={{ outline: "none" }}
                  name="name"
                  value={formState.name}
                  onChange={(event) => {
                    setFormState((formState) => ({
                      ...formState,
                      name: event.target.value,
                    }));
                  }}
                />
                <FormErrorMessage>Bitte sag uns, wer du bist</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={hasVerificationError && !hasConfirmation}>
                <FormLabel mb={1}>Sagst du zu oder ab?</FormLabel>
                <VStack alignItems="flex-start">
                  <Checkbox
                    colorScheme="green"
                    onChange={(event) =>
                      setFormState((formState) => ({
                        ...formState,
                        confirmationState:
                          !event.target.checked &&
                          formState.confirmationState === true
                            ? undefined
                            : true,
                      }))
                    }
                    isChecked={formState.confirmationState === true}
                    name="zusage"
                  >
                    Ich sage zu :)
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    onChange={(event) =>
                      setFormState((formState) => ({
                        ...formState,
                        confirmationState:
                          !event.target.checked &&
                          formState.confirmationState === false
                            ? undefined
                            : false,
                      }))
                    }
                    isChecked={formState.confirmationState === false}
                    name="absage"
                  >
                    Ich sage ab :(
                  </Checkbox>
                </VStack>
                <FormErrorMessage>
                  Bitte sag uns, ob du kommen kannst
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={hasVerificationError && !hasNumberOfPersons}
              >
                <FormLabel mb={1}>Mit wievielen Personen kommst du? (inkl. dir)</FormLabel>
                <Input
                  type="number"
                  color={useColorModeValue("gray.800", "gray.200")}
                  bg={useColorModeValue("gray.100", "gray.600")}
                  rounded={"full"}
                  border={0}
                  _focus={{
                    bg: useColorModeValue("gray.200", "gray.800"),
                    outline: "none",
                  }}
                  _active={{ outline: "none" }}
                  name="numberOfPersons"
                  value={formState.numberOfPersons}
                  onChange={(event) => {
                    setFormState((formState) => ({
                      ...formState,
                      numberOfPersons: event.target.value,
                    }));
                  }}
                />
                <FormErrorMessage>
                  Super, dass du zugesagt hast! Aber wir müssten wissen, mit
                  wievielen Personen du kommst
                </FormErrorMessage>
              </FormControl>
              <input type="hidden" name="form-name" value="confirmation" />
              <Button
                rounded={"full"}
                px={6}
                colorScheme={"orange"}
                bg={"#98a967"}
                _hover={{ bg: "#7b8953" }}
                type="submit"
                isLoading={submissionState === "submitting"}
                leftIcon={
                  submissionState === "submitted" ? <CheckIcon /> : undefined
                }
                onClick={(e) => {
                  const hasName = !!formState.name;
                  const hasNumberOfPersons =
                    !formState.confirmationState || !!formState.numberOfPersons;

                  if (
                    hasName &&
                    formState.confirmationState !== undefined &&
                    hasNumberOfPersons
                  ) {
                    setHasVerificationErrors(false);
                    return;
                  }
                  setHasVerificationErrors(true);
                  e.preventDefault();
                }}
                isDisabled={submissionState === "submitted"}
              >
                {submissionState === "idle"
                  ? "Abschicken"
                  : submissionState === "submitting"
                  ? "Wird abgeschickt..."
                  : submissionState === "submitted"
                  ? "Abgeschickt"
                  : "Abschicken"}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Flex>
    </VStack>
  );
};
