import {
  Center,
  ChakraProvider,
  ColorModeScript,
  Image,
  extendTheme,
} from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from "./App";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const ErrorPage = () => (
  <Center height="100vh">
    <Image src="/404.svg" maxWidth="90vw" m="auto" />
  </Center>
);

const router = createBrowserRouter([
  { path: "heiraten", element: <App /> },
  {
    index: true,
    errorElement: <ErrorPage />,
    element: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <ChakraProvider
      theme={extendTheme({
        colors: {
          green: {
            "50": "#F4F6EF",
            "100": "#E0E5D1",
            "200": "#CCD5B4",
            "300": "#B8C497",
            "400": "#A5B379",
            "500": "#91A35C",
            "600": "#74824A",
            "700": "#576237",
            "800": "#3A4125",
            "900": "#1D2112",
          },
        },
      })}
      resetCSS
    >
      <RouterProvider router={router} />
    </ChakraProvider>
    <ColorModeScript />
  </React.StrictMode>
);
