import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Grid, Heading, Image, Link, VStack } from "@chakra-ui/react";

export const Locations = () => {
  return (
    <VStack>
      <Heading mb={4}>Locations</Heading>
      <Grid
        gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
        gridTemplateRows={{ base: "1fr auto 1fr auto", md: "1fr auto" }}
        gridAutoFlow="column"
        textAlign="center"
      >
        <Image src="./wedding.svg" width={{ base: "50%" }} m="auto" />
        <VStack mt={4} mb={8} spacing={0}>
          <Heading as="h3" fontSize="lg">
            Lutherkirche
          </Heading>
          <Link href="https://goo.gl/maps/trhuPrEVMnm23BDF7" isExternal>
            Luisenstraße 7<br />
            76646 Bruchsal <ExternalLinkIcon mx={0.5} mb={1} />
          </Link>
        </VStack>
        <Image src="./party.svg" width={{ base: "50%" }} m="auto" />
        <VStack mt={4} mb={8} spacing={0}>
          <Heading as="h3" fontSize="lg">
            Schützenvereinsheim
          </Heading>
          <Link href="https://goo.gl/maps/2f5H338viQFEuTU17" isExternal>
            Rötzenweg 1<br />
            76646 Bruchsal <ExternalLinkIcon mx={0.5} mb={1} />
          </Link>
        </VStack>
      </Grid>
    </VStack>
  );
};
