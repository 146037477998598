import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Parken = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <VStack>
      <Heading mb={4}>Wo kann ich parken?</Heading>
      <Container
        display="flex"
        maxW={{ base: "100%", lg: "80%" }}
        flexDirection="column"
        gap={12}
      >
        <Box
          marginTop={{ base: "1", sm: "5" }}
          display="flex"
          flexDirection={{ base: "column-reverse", sm: "row" }}
          justifyContent="space-between"
          gap={{ base: 6, lg: 0 }}
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: "100%", sm: "85%" }}
              zIndex="2"
              marginLeft={{ base: "0", sm: "5%" }}
              marginTop="5%"
            >
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                <Image
                  borderRadius="lg"
                  src="Parken Kirche.png"
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box
              zIndex="1"
              width="100%"
              position="absolute"
              height="100%"
              right={{ base: "-30px", lg: "0" }}
              top="-10px"
            >
              <Box
                bgGradient="radial(#98a967 1px, transparent 1px)"
                backgroundSize="20px 20px"
                opacity="0.4"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: "3", sm: "0" }}
            fontSize="lg"
          >
            <Heading marginTop="1">Kirche</Heading>
            <Text mt={2}>
              Es gibt einige öffentliche Parkplätze an den Straßen, die der
              Kirche angrenzen. Diese sind jedoch vor allem Samstag mittags
              meistens belegt. Daher sind die folgenden Parkhäuser eine sichere
              Alternative:
            </Text>
            <Box pt={4}>
              <VStack pl={0} spacing={2} alignItems="flex-start">
                <Link href="https://goo.gl/maps/rdZGTkKExBmvNXQ8A" isExternal>
                  Parkhaus Rathausgalerie (350m){" "}
                  <ExternalLinkIcon mx={0.5} mb={1} />
                </Link>
                <Link href="https://goo.gl/maps/CCfV8kSjpRsR3k5w7" isExternal>
                  Parkhaus Bürgerzentrum (550m){" "}
                  <ExternalLinkIcon mx={0.5} mb={1} />
                </Link>
                <Link href="https://goo.gl/maps/RcXun1RXNro2RHUq7" isExternal>
                  Parkhaus Saalbachcenter (700m){" "}
                  <ExternalLinkIcon mx={0.5} mb={1} />
                </Link>
              </VStack>
            </Box>
          </Box>
        </Box>
        <Box
          marginTop={{ base: "1", sm: "5" }}
          display="flex"
          flexDirection={{ base: "column-reverse", sm: "row-reverse" }}
          justifyContent="space-between"
          gap={{ base: 6, lg: 0 }}
          id="und-hier-kannst-du-parken"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: "100%", sm: "85%" }}
              zIndex="2"
              marginLeft={{ base: "0", sm: "5%" }}
              marginTop="5%"
            >
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                <Image
                  borderRadius="lg"
                  src="Parken Feier.png"
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box
              zIndex="1"
              width="100%"
              position="absolute"
              height="100%"
              right={{ base: "-30px", lg: "0" }}
              top="-10px"
            >
              <Box
                bgGradient="radial(#98a967 1px, transparent 1px)"
                backgroundSize="20px 20px"
                opacity="0.4"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: "3", sm: "0" }}
            fontSize="lg"
          >
            <Heading marginTop="1">Feier</Heading>
            <Text mt={2}>
              Bitte nicht direkt an der Straße vor der Location parken, da diese
              samstags benutzt wird und frei bleiben muss. Es gibt allerdings
              einige öffentlich Parkplätze in der Nähe:
            </Text>
            <Box pt={4}>
              <VStack pl={0} spacing={3} alignItems="flex-start">
                <Link href="https://goo.gl/maps/uF9j89swEEpBu21x9" isExternal>
                  Parkplatz Friedhof (450m) <ExternalLinkIcon mx={0.5} mb={1} />
                </Link>
                <Link href="https://goo.gl/maps/Xp7JBFqg3uenisGa6" isExternal>
                  Parkplatz Bahnhof (600m) <ExternalLinkIcon mx={0.5} mb={1} />
                </Link>
                <Link href="https://goo.gl/maps/GFU1buJcHy5XZ7ZH8" isExternal>
                  Parkplatz Bahnhof 2 (650m){" "}
                  <ExternalLinkIcon mx={0.5} mb={1} />
                </Link>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Container>
    </VStack>
  );
};
