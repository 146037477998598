import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

export const Itinerary = () => {
  return (
    <VStack spacing="-1px" alignItems="stretch" id="mehrInfos">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#98a967"
          fill-opacity="1"
          d="M0,224L60,224C120,224,240,224,360,229.3C480,235,600,245,720,240C840,235,960,213,1080,181.3C1200,149,1320,107,1380,85.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
      <VStack background="#98a967" color="#feffff" position="relative">
        <Heading mb={4}>Ablauf</Heading>
        <Grid
          gridTemplateRows="repeat(6, 1fr)"
          gridTemplateColumns="1fr 25px 1fr"
          gridAutoFlow="column"
          rowGap={6}
        >
          <GridItem>
            <Box
              background="white"
              borderRadius="full"
              padding={3}
              width="fit-content"
              m="auto"
            >
              <Image src="trauung.png" mr={0} width="3rem" height="3rem" />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              background="white"
              borderRadius="full"
              padding={3}
              width="fit-content"
              m="auto"
            >
              <Image src="sekt.png" mr={0} width="3rem" height="3rem" />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              background="white"
              borderRadius="full"
              padding={3}
              width="fit-content"
              m="auto"
            >
              <Image src="snacks.png" mr={0} width="3rem" height="3rem" />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              background="white"
              borderRadius="full"
              padding={3}
              width="fit-content"
              m="auto"
            >
              <Image src="kuchen.png" mr={0} width="3rem" height="3rem" />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              background="white"
              borderRadius="full"
              padding={3}
              width="fit-content"
              m="auto"
            >
              <Image src="roses.png" mr={0} width="3rem" height="3rem" />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              background="white"
              borderRadius="full"
              padding={3}
              width="fit-content"
              m="auto"
            >
              <Image src="musik.png" mr={0} width="3rem" height="3rem" />
            </Box>
          </GridItem>
          <GridItem rowSpan={6} display="flex" justifyContent="center">
            <Divider orientation="vertical" borderColor="white" />
          </GridItem>
          <GridItem>
            <VStack
              spacing={0}
              alignItems="flex-start"
              height="100%"
              justifyContent="center"
            >
              <Text>13:30 Uhr</Text>
              <Text>Trauung</Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              spacing={0}
              alignItems="flex-start"
              height="100%"
              justifyContent="center"
            >
              <Text>14:30 Uhr</Text>
              <Text>Sektempfang</Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              spacing={0}
              alignItems="flex-start"
              height="100%"
              justifyContent="center"
            >
              <Text>16:00 Uhr</Text>
              <Text>Snacks & Drinks</Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              spacing={0}
              alignItems="flex-start"
              height="100%"
              justifyContent="center"
            >
              <Text>18:00 Uhr</Text>
              <Text>Abendessen</Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              spacing={0}
              alignItems="flex-start"
              height="100%"
              justifyContent="center"
            >
              <Text>20:00 Uhr</Text>
              <Text>Brautstraußwerfen</Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              spacing={0}
              alignItems="flex-start"
              height="100%"
              justifyContent="center"
            >
              <Text>22:00 Uhr</Text>
              <Text>Party & Chillen</Text>
            </VStack>
          </GridItem>
        </Grid>
      </VStack>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#98a967"
          fill-opacity="1"
          d="M0,192L120,202.7C240,213,480,235,720,213.3C960,192,1200,128,1320,96L1440,64L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
        ></path>
      </svg>
    </VStack>
  );
};
