import { Box, VStack } from "@chakra-ui/react";

export const Trauspruch = () => {
  return (
    <VStack spacing="-1px" position="relative">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EDF2F7"
          fill-opacity="1"
          d="M0,128L80,133.3C160,139,320,149,480,176C640,203,800,245,960,250.7C1120,256,1280,224,1360,208L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
      <Box height="100px" background="#EDF2F7" width="100%" />
      {/* 
      <Image
        src="quotation mark.png"
        position="absolute"
        left="20%"
        top="35%"
        width="200px"
        transform="scaleY(-1)"
      />
      <Container
        maxW={{ base: "full", lg: "50%" }}
        mt={0}
        centerContent
        overflow="hidden"
        position="absolute"
        top="47%"
      >
        <Text fontSize="xl" pb={4}>
          Vollkommen aber ist meine Freude, wenn ihr euch ganz einig seid, in
          der einen Liebe miteinander verbunden bleibt und fest zusammenhaltet.
          Weder Eigennutz noch Streben nach Ehre sollen euer Handeln bestimmen,
          Im Gegenteil, seid bescheiden, und achtet den anderen mehr als euch
          selbst.
        </Text>
        <Text fontSize="lg" fontStyle="italic" textAlign="right" width="100%">
          -Philipper 2:2-3
        </Text>
      </Container> */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EDF2F7"
          fill-opacity="1"
          d="M0,128L80,133.3C160,139,320,149,480,176C640,203,800,245,960,250.7C1120,256,1280,224,1360,208L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
    </VStack>
  );
};
